import { Grid } from "@mui/material";
import React from "react";
import { FC } from "react";
import { Pokemon } from "../App";
import { PokemonPreviewCard } from "./PokemonPreviewCard";

export const PokemonGrid: FC<{selectedPokemon: Pokemon[]}> = (props) => {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems='stretch'>
      {props.selectedPokemon.map((pokemon) => {
        return (
          <Grid
            item
            key={pokemon.id}
            // xs={12}
            sm={3}
            md={2}
          >
            <PokemonPreviewCard pokemon={pokemon} />
          </Grid>
        );
      })}
    </Grid>
  );
};
