import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Pokemon, useGlobalPokemon } from '../App';

export default function PokemonDetail() {
  const params = useParams();
  const [pokemon] = useGlobalPokemon();
  const [thisPokemon, setThisPokemon] = useState<Pokemon | null>(null);

  useEffect(() => {
    const foundPokemon = pokemon?.find(
      (pokemon) => pokemon.id === Number(params.id)
    );

    if (foundPokemon !== undefined) {
      // If we found a Pokemon, set thisPokemon
      setThisPokemon(foundPokemon);
    } else {
      // If we didn't find a Pokemon, make an API call
      axios
        .get<Pokemon>(`https://pokeapi.co/api/v2/pokemon/${params.id}`)
        .then((response) => {
          setThisPokemon(response.data);
        });
    }
  }, [params.id, pokemon]);

  return (
    <Stack alignItems="center" spacing={2}>
      {thisPokemon !== null && (
        <>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6} sm={4} md={2}>
              <Button
                disabled={thisPokemon.id - 1 < 1}
                variant="contained"
                startIcon={<ArrowBackIcon />}
                component={RouterLink}
                to={`/pokemon/${thisPokemon?.id - 1}`}
                fullWidth
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                component={RouterLink}
                to={`/pokemon/${thisPokemon?.id + 1}`}
                fullWidth
              >
                Next
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={8} md={6}>
            <Card sx={{ width: '100%' }}>
              <CardMedia>
                <Grid
                  container
                  item
                  justifyContent="center"
                  sx={{ userSelect: 'none' }}
                >
                  <img
                    src={thisPokemon.sprites.front_default}
                    alt={`Pixel art of ${thisPokemon.name}`}
                    width="50%"
                  />
                </Grid>
              </CardMedia>
              <CardContent>
                <Typography variant="h2" textAlign="center">
                  {thisPokemon?.name}
                </Typography>

                <Grid container alignItems="stretch">
                  <Grid
                    item
                    container
                    xs={4}
                    textAlign="center"
                    alignSelf="center"
                    justifyContent="center"
                  >
                    <Typography fontStyle="italic">
                      Height: {thisPokemon.height}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    textAlign="center"
                    alignSelf="center"
                    justifyContent="center"
                  >
                    <Typography fontStyle="italic">
                      ID: {thisPokemon.id}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={4}
                    textAlign="center"
                    alignSelf="center"
                    justifyContent="center"
                  >
                    <Typography fontStyle="italic">
                      Weight: {thisPokemon.weight}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <hr></hr>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5">Abilities</Typography>
                    <List>
                      {thisPokemon?.abilities.map((ability, i) => (
                        <ListItem key={i}>
                          <Typography>{ability.ability.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                    <Typography variant="h5">Types</Typography>
                    <List>
                      {thisPokemon?.types.map((type, i) => (
                        <ListItem key={i}>
                          <Typography>{type.type.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5">Stats</Typography>
                    <List>
                      {thisPokemon?.stats.map((pokemonStat, i) => (
                        <ListItem key={i}>
                          <Typography>{`${pokemonStat.stat.name} : ${pokemonStat.base_stat}`}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </Stack>
  );
}
