import React, { FC } from 'react';
import { Card, CardActionArea, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Pokemon } from '../App';

export const PokemonPreviewCard: FC<{ pokemon: Pokemon }> = (props) => {
  const pokemon = props.pokemon;
  
  return (
    <Card sx={{height: '100%'}}>
      <CardActionArea
        to={`/pokemon/${pokemon.id}/`}
        component={RouterLink}
        sx={{ p: 2 }}
      >
        <Stack alignItems="center">
          <Typography fontWeight="bold">{pokemon.name}</Typography>
          <img
            src={pokemon.sprites.front_default}
            width="80%"
            alt={`Pixel art of ${pokemon.name}`}
          />
          <Typography fontStyle="italic">{pokemon.id}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
