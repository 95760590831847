import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App, { Home } from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { CssBaseline } from '@mui/material';
import PokemonDetail from './components/PokemonDetail';
import Search from './components/Search';

let theme = createTheme({
  palette: { mode: 'dark' },
  typography: { fontFamily: ['Silkscreen', 'Times New Roman'].join(',') },
});
theme = responsiveFontSizes(theme);

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
      children: [
        {
          children: [
            { index: true, element: <Home /> },
            { path: 'search', element: <Search /> },
            { path: 'pokemon/:id', element: <PokemonDetail /> },
          ],
        },
      ],
    },
  ],
  { basename: '/' }
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
