import {
  AppBar,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import CategoryIcon from '@mui/icons-material/Category';
import GridViewIcon from '@mui/icons-material/GridView';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, Outlet, useOutletContext } from 'react-router-dom';
import './App.css';
import { PokemonPreviewCard } from './components/PokemonPreviewCard';

type PokemonResults = {
  name: string;
  url: string;
};

export type Pokemon = {
  name: string;
  id: number;
  height: number;
  weight: number;
  sprites: {
    front_default: string;
  };
  abilities: Array<{ ability: { name: string } }>;
  types: Array<{ type: { name: string } }>;
  stats: Array<{ base_stat: string; effort: number; stat: { name: string } }>;
};

function App() {
  const [allPokemon, setAllPokemon] = useState<null | Pokemon[]>(null);

  return (
    <div className="App">
      {/* <header className="App-header"></header> */}
      <AppBar position="sticky" sx={{ mt: 0 }}>
        <Grid
          //  sx={{marginLeft: 'auto', marginRight: 'auto'}}
          // maxWidth='md'
          container
          justifyContent="center"
          // alignItems="stretch"
          alignItems="center"
          spacing={2}
          textAlign="center"
          // width="100%"
        >
          <Grid item container xs={4}>
            <Button
              startIcon={<SearchIcon />}
              component={RouterLink}
              to="/search"
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Link to="/" component={RouterLink} underline="none">
              {/* <Grid item> */}
              <img
                src="/pokefinder.svg"
                alt="Pokefinder logo"
                id="pokefinder-logo"
              />
              {/* </Grid> */}
              {/* <Grid item>
                <Typography
                  variant="h4"
                  component="h1"
                  fontWeight="bold"
                  display={{ xs: 'none', sm: 'none', md: 'flex' }}
                >
                  <Link to="/" component={RouterLink} underline="none">
                    Pokéfinder
                  </Link>
                </Typography>
              </Grid> */}
            </Link>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button startIcon={<GridViewIcon />} component={RouterLink} to="/">
              Gallery
            </Button>
          </Grid>
        </Grid>
      </AppBar>
      <Container sx={{ pb: 5, pt: 2 }}>
        <Outlet context={[allPokemon, setAllPokemon]} />
      </Container>
    </div>
  );
}

const allPokemonTypes = [
  'all',
  'normal',
  'fire',
  'water',
  'grass',
  'electric',
  'ice',
  'fighting',
  'poison',
  'ground',
  'flying',
  'psychic',
  'bug',
  'rock',
  'ghost',
  'dragon',
  'steel',
  'fairy',
];
export function Home() {
  const [allPokemon, setAllPokemon] = useGlobalPokemon();
  const [selectedPokemon, setSelectedPokemon] = useState<Pokemon[] | null>(
    null
  );
  const [selectedType, setSelectedType] = useState<string>('all');

  useEffect(() => {
    // Don't make API call if pokemon already loaded
    if (allPokemon !== null) {
      setSelectedType('all');
      setSelectedPokemon(allPokemon);
      return;
    }
    fetchAllPokemon().then((fetchedPokemon) => {
      setAllPokemon(fetchedPokemon);
      setSelectedPokemon(fetchedPokemon);
    });
  }, [allPokemon, setAllPokemon]);

  useEffect(() => {
    console.log('loaded');
  }, []);

  function handleFilterChange(pokemonType: string) {
    setSelectedType(pokemonType);

    if (pokemonType === 'all' || allPokemon === null) {
      setSelectedPokemon(allPokemon);
      return;
    }

    const newSelection = allPokemon.filter((pokemon) => {
      for (
        let pokeTypeIdx = 0;
        pokeTypeIdx < pokemon.types.length;
        pokeTypeIdx++
      ) {
        const pokeType = pokemon.types[pokeTypeIdx];
        if (pokeType.type.name === pokemonType) {
          return true;
        }
      }
      return false;
    });
    setSelectedPokemon(newSelection);
  }

  return (
    <Stack spacing={1}>
      {/* Filter buttons */}
      <Grid container spacing={1} justifyContent="center">
        {allPokemonTypes.map((pokemonType, i) => {
          return (
            <Grid item key={i}>
              {/* Change button appearance if selected */}
              <Button
                variant={
                  pokemonType === selectedType ? 'contained' : 'outlined'
                }
                onClick={() => handleFilterChange(pokemonType)}
                startIcon={pokemonType === 'all' && <CategoryIcon />}
              >
                {pokemonType}
              </Button>
            </Grid>
          );
        })}
      </Grid>
      {selectedPokemon === null ? (
        <></>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          {selectedPokemon.map((pokemon) => {
            return (
              <Grid item key={pokemon.id} sm={3} md={2}>
                <PokemonPreviewCard pokemon={pokemon} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Stack>
  );
}

export function useGlobalPokemon() {
  return useOutletContext<
    [Pokemon[] | null, React.Dispatch<React.SetStateAction<Pokemon[] | null>>]
  >();
}

export function fetchAllPokemon() {
  // Get list of all Pokemon
  return axios
    .get('https://pokeapi.co/api/v2/pokemon?limit=151')
    .then((res) => {
      const pokemon = res.data.results as PokemonResults[];
      const pokemonURLs = pokemon.map((poke) => poke.url);

      const pokemonRequests = pokemonURLs.map((url) => axios.get<Pokemon>(url));

      // Make requests to all Pokemon URLs
      return Promise.all(pokemonRequests).then((allPokemonResults) => {
        const pokemonFromRequests = allPokemonResults.map((r) => r.data);
        return pokemonFromRequests;
      });
    });
}

export default App;
