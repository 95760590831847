import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchAllPokemon, Pokemon, useGlobalPokemon } from '../App';
import { PokemonGrid } from './PokemonGrid';

export default function Search() {
  const [allPokemon, setAllPokemon] = useGlobalPokemon();
  const [selectedPokemon, setSelectedPokemon] = useState<Pokemon[] | null>(
    null
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortBy, setSortBy] = useState<'name' | 'id'>('name');
  const [sortOrder, setSortOrder] = useState<'ascending' | 'descending'>(
    'ascending'
  );

  useEffect(() => {
    if (allPokemon === null) {
      fetchAllPokemon().then((fetchedPokemon) => {
        setAllPokemon(fetchedPokemon);
        setSelectedPokemon(fetchedPokemon);
      });
    } else {
      setSelectedPokemon(allPokemon);
    }
  }, [allPokemon, setAllPokemon]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputContent = event.target.value;
    setSearchValue(inputContent);
  };

  const handleFilterChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as 'name' | 'id');
  };

  // When searchValue or sortBy or sortOrder are changed, update search results
  useEffect(() => {
    // Search by
    const searchResults = allPokemon?.filter((pokemon) =>
      pokemon.name.includes(searchValue)
    );

    let sortedResults: Pokemon[] | undefined = [];

    if (sortBy === 'name') {
      // If searching by name, sort by name
      sortedResults = searchResults?.sort((a, b) => {
        if (sortOrder === 'ascending') {
          // If sort order is ascending, sort in ascending alphabetical order
          return a.name < b.name ? -1 : 1;
        } else {
          // If sort order is descending, sort in descending alphabetical order
          return a.name > b.name ? -1 : 1;
        }
      });
    } else if (sortBy === 'id') {
      // If searching by id, sort by id
      sortedResults = searchResults?.sort((a, b) => {
        if (sortOrder === 'ascending') {
          // If sort order is ascending, sort in ascending numerical order
          return a.id < b.id ? -1 : 1;
        } else {
          // If sort order is descending, sort in descending numerical order
          return a.id > b.id ? -1 : 1;
        }
      });
    }

    // Set Pokemon in grid to sorted search results
    setSelectedPokemon(sortedResults !== undefined ? sortedResults : null);
  }, [searchValue, sortBy, sortOrder, allPokemon]);

  return (
    <Stack spacing={1}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            id="outlined-basic"
            label="🔍 Search"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <InputLabel id="order-by-label">Order By</InputLabel>
            <Select
              labelId="order-by-label"
              value={sortBy}
              fullWidth
              onChange={handleFilterChange}
            >
              <MenuItem value={'name'}>Name (alphabetical)</MenuItem>
              <MenuItem value={'id'}>ID</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Button
                variant={sortOrder === 'ascending' ? 'contained' : 'outlined'}
                startIcon={<ArrowUpwardIcon />}
                onClick={() => {
                  setSortOrder('ascending');
                }}
              >
                Ascending
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={sortOrder === 'descending' ? 'contained' : 'outlined'}
                startIcon={<ArrowDownwardIcon />}
                onClick={() => {
                  setSortOrder('descending');
                }}
              >
                Descending
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {selectedPokemon !== null && (
        <PokemonGrid selectedPokemon={selectedPokemon} />
      )}
    </Stack>
  );
}
